import { createContext, useContext, useState } from 'react';

const StoreContext = createContext();

export const StoreProvider = ({ children }) => {
  const [store, setStore] = useState({});

  const setItem = (key, value) => {
    setStore((prevStore) => ({
      ...prevStore,
      [key]: value,
    }));
  };

  const getItem = (key) => store[key];

  return (
    <StoreContext.Provider value={{ setItem, getItem }}>
      {children}
    </StoreContext.Provider>
  );
};

export const useStore = () => useContext(StoreContext);
