import { useContext, useState } from 'react';
import { sendEmailVerification } from 'firebase/auth';
import { UserContext } from 'Utils/UserContext';
import toast from 'react-hot-toast';

import { UserSubscriptions, UserNotifications } from 'Components';

import {
  Avatar
} from 'Common/Components';

import {
  Container,
  Button,
  CardMeta,
  CardHeader,
  CardDescription,
  CardContent,
  Card,
  Message,
  MessageHeader,
  ListItem,
  ListHeader,
  List,
  Label
} from 'semantic-ui-react';

import {
  Text
} from 'Common/Components';

const UserPage = () => {
  const { user } = useContext(UserContext);
  const [disabled, setDisabled] = useState(false);

  const verifyEmail = () => {
    setDisabled(true);
    sendEmailVerification(user.account.auth.currentUser)
      .then(() => {
        toast.success('Verification email sent.');
      });
  };

  if (user.auth) return (
    <Container>

      {!user.account.emailVerified &&
        <Message error>
          <MessageHeader>Your email address is not verified</MessageHeader>
          <p>Please verify your email address to access all features.</p>
          <Button basic negative disabled={disabled} size='mini' onClick={e => verifyEmail()}>Verify email</Button>
        </Message>
      }

      <Card.Group itemsPerRow={2}>
        <Card>
          <CardContent>
            <CardHeader>Account</CardHeader>
            <CardMeta>
              Your account details
            </CardMeta>
            <CardDescription>
              <List>
                <ListItem>
                  <ListHeader>Display Name</ListHeader>
                  {user.profile.displayName}
                </ListItem>
                <ListItem>
                  <ListHeader>Email Address</ListHeader>
                  {user.profile.email}
                </ListItem>
                <ListItem>
                  <ListHeader>Account Verified</ListHeader>
                  {user.account.emailVerified ? 'Yes' : 'No'}
                </ListItem>
                <ListItem>
                  <ListHeader>Avatar</ListHeader>
                  <Avatar username={user.profile.displayName} editable />
                </ListItem>
              </List>
            </CardDescription>
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <CardHeader>Subscription</CardHeader>
            <CardMeta>
              View and manage your current TeamRL subscription
            </CardMeta>
            <CardDescription>
              <UserSubscriptions />
            </CardDescription>
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <CardHeader>Notifications</CardHeader>
            <CardMeta>
              <Label size="small" color="yellow">BETA</Label> Manage your notification settings.
            </CardMeta>
            <CardDescription>
                <Text>
                TeamRL offer web and push notifications to supporting devices. These
                notifications will only ever include live score updates or discussion
                updates if you're subscribed to a topic.
                </Text>

                <Text as="b">
                This feature requires an active subscription and will launching
                soon.
                </Text>
            </CardDescription>
          </CardContent>
          <CardContent extra>
            {user.profile.notify &&
              <Message compact size="tiny">
                Notifications for this account are <UserNotifications />
              </Message>
            }
          </CardContent>
        </Card>
      </Card.Group>
    </Container>
  );
}

export default UserPage;
