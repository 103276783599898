import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  Message
} from 'semantic-ui-react'

import { DateFormatNoTime } from 'Utils/DateFormat';
import { ListNews } from 'API/getNews';

import {
  SegmentGroup,
  Segment,
  Header,
  List,
  Icon
} from 'semantic-ui-react';

const NewsList = () => {
  const [newsList, setNewsList] = useState([]);

  useEffect(() => {
    ListNews().then(response => {
      setNewsList(response);
    }).catch(error => {
      console.log(error);
    });
  }, []);

  return (
    <SegmentGroup>
      <Segment color="blue">
        <Header as='h3' style={{ marginBottom: '0' }}>
          News
        </Header>
      </Segment>
      <Segment>
        {newsList.length === 0 &&
          <Message info>
            No published articles
          </Message>
        }
        <List relaxed>
          {newsList.map((item, index) => (
            <List.Item key={index}>
              <List.Content>
                <List.Header as={Link} to={`/news/${item.id}`} style={{ fontSize: '1.2em' }}>
                  <Icon name='newspaper' />
                  {item.title}
                </List.Header>
                <List.Description style={{ fontSize: '0.9em' }}>
                  {DateFormatNoTime(item.published)}
                </List.Description>
              </List.Content>
            </List.Item>
          ))}
        </List>
      </Segment>
    </SegmentGroup>
  );
}

export default NewsList;
