const ClubBadgeImage = (props) => {
  return (
    <img
      src={`/badges/${props.team}.png`}
      alt={props.team}
      onError={(e) => { e.target.onerror = null; e.target.src = '/badges/default.png'; }}
    />
  );
}

export default ClubBadgeImage;
