export const DateFormat = (timestamp, expires) => {
  const date = new Date(timestamp);

  if (expires) {
    date.setDate(date.getDate() + 14);
  }

  const formatDate = date.toLocaleDateString("en-GB");
  const formatTime = date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', hour12: true });

  if (expires) {
    return `${formatDate}`;
  } else {
    return `${formatDate} ${formatTime}`;
  }
};

export const DateFormatNoTime = (timestamp) => {
  const date = new Date(timestamp);
  const formatDate = date.toLocaleDateString("en-GB");
  return `${formatDate}`;
};

export const DateFormatShort = (timestamp) => {
  const date = new Date(timestamp);
  const options = { day: '2-digit', month: 'short', year: '2-digit', hour: 'numeric', minute: 'numeric', hour12: true };
  let formattedDate = date.toLocaleDateString('en-GB', options).replace(',', ' •');
  formattedDate = formattedDate.replace(/ (\d{1,2}:\d{2})( )([ap]m)/, ' $1$3');

  return formattedDate;
};

export const RelativeDateFormat = (timestamp) => {
  const date = new Date(timestamp);
  const now = new Date();
  const diffInMs = now - date;
  const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

  if (diffInDays === 0) {
    const formatTime = date.toLocaleTimeString('en-GB', { hour: 'numeric', minute: 'numeric', hour12: true });
    return `Today at ${formatTime.replace(/ (\d{1,2}:\d{2})( )([ap]m)/, ' $1$3')}`;
  } else if (diffInDays === 1) {
    return 'Yesterday';
  } else if (diffInDays < 7) {
    return `${diffInDays} days ago`;
  } else {
    const options = { day: '2-digit', month: 'short', year: '2-digit', hour: 'numeric', minute: 'numeric', hour12: true };
    let formattedDate = date.toLocaleDateString('en-GB', options).replace(',', ' •');
    formattedDate = formattedDate.replace(/ (\d{1,2}:\d{2})( )([ap]m)/, ' $1$3');
    return formattedDate;
  }
};
