import { useState, useContext } from 'react';
import toast from 'react-hot-toast';
import { app } from 'Utils/Firebase';
import { UserContext } from 'Utils/UserContext';
import { createCheckoutSession, getStripePayments } from "@stripe/firestore-stripe-payments";

import {
  Container,
  Button,
  ButtonOr,
  GridRow,
  GridColumn,
  Divider,
  Grid,
  Header,
  List,
  ListItem,
  Segment,
} from 'semantic-ui-react'

const SubscribePage = () => {
  const { user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);

  const payments = getStripePayments(app, {
    productsCollection: "subscriptions",
    customersCollection: "users",
  });

  const createSubscription = async (subType) => {
    setIsLoading(true);
    toast.success('Redirecting you to the payment screen...');
    const session = await createCheckoutSession(payments, {
      price: subType,
      allow_promotion_codes: true,
    });
    window.location.href = session.url;
  };

  return (
    <Container>
      <Header as='h2'>Subscribe to TeamRL</Header>

      Your season ticket to exclusive content. In addition to helping the website
      you'll also gain some new features, with more being added all the time.

      <Segment placeholder>
        <Grid columns={2} stackable textAlign='center'>
          <Divider vertical>•</Divider>

          <GridRow verticalAlign='middle'>
            <GridColumn>
              <Header>
                Features
              </Header>

              <List>
                <ListItem content='No Advertisements' />
                <ListItem content='Create unlimited discussions' />
                <ListItem content='Username highlighted in discussions & live chat' />
                <ListItem content='Access additional quick replies in chat (Coming soon)' />
                <ListItem content='Advanced notifications (Coming soon)' />
              </List>
            </GridColumn>

            <GridColumn>
              {user.auth
                ?
                <>
                  <Button.Group>
                    <Button
                      size="tiny"
                      primary
                      onClick={() => createSubscription('price_1MjKxIJGclKqVLCRyTaiIg7D')}
                      loading={isLoading}
                      disabled={isLoading}
                    >
                      £19.99 / Yearly
                    </Button>
                    <ButtonOr />
                    <Button
                      size="tiny"
                      onClick={() => createSubscription('price_1MjKx7JGclKqVLCRJW7mWIds')}
                      loading={isLoading}
                      disabled={isLoading}
                    >
                      £1.99 / Monthly
                    </Button>
                  </Button.Group>
                  <p>Save over 17% with the yearly subscription!</p>
                </>
              :
              <p>Please login to purchase a subscription</p>
              }
            </GridColumn>
          </GridRow>
        </Grid>
      </Segment>
    </Container>
  );
}

export default SubscribePage;
