import { useEffect, useState } from 'react';
import { getFixtures } from '../../api/fixtures';

import {
  Table
} from 'semantic-ui-react';

const ListFixtures = () => {
  const [fixtures, setFixtures] = useState([]);

  const fetchClubs = async () => {
    try {
      const response = await getFixtures();
      console.log(response);
      setFixtures(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchClubs();
  }, []);

  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Home</Table.HeaderCell>
          <Table.HeaderCell>Away</Table.HeaderCell>
          <Table.HeaderCell>Date</Table.HeaderCell>
          <Table.HeaderCell>Score Provider</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell>asdasd</Table.Cell>
          <Table.Cell>asdasd</Table.Cell>
          <Table.Cell>asdasd</Table.Cell>
          <Table.Cell>asdasd</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};

export default ListFixtures;
