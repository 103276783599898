import { app } from 'Utils/Firebase';

import {
  getFirestore,
  collection,
  getDocs,
  query,
  setDoc,
  doc,
  updateDoc,
  getDoc,
} from 'firebase/firestore/lite';

const db = getFirestore(app);

export const getFixtures = async () => {
  const listRef = collection(db, "fixtures");
  const q = query(listRef);
  const getCollection = await getDocs(q);
  const list = getCollection.docs.map((doc) => ({id: doc.id, ...doc.data()}))
  return list;
};

export const createFixtureRound = async (data) => {
  const roundId = `round-${data.roundNumber}`;
  const roundRef = doc(db, 'fixtures', roundId);
  const roundDoc = await getDoc(roundRef);

  if (roundDoc.exists()) {
    // Update existing round
    const existingData = roundDoc.data();
    const updatedData = {
      ...existingData,
      [data.selectedLeague]: {
        ...existingData[data.selectedLeague],
        ...data.fixtures.reduce((acc, fixture) => {
          const fixtureId = `${fixture.homeTeam}-${fixture.awayTeam}`;
          acc[fixtureId] = fixture;
          return acc;
        }, {})
      }
    };
    await updateDoc(roundRef, updatedData);
  } else {
    // Create new round
    const newData = {
      id: roundId,
      [data.selectedLeague]: data.fixtures.reduce((acc, fixture) => {
        const fixtureId = `${fixture.homeTeam}-${fixture.awayTeam}`;
        acc[fixtureId] = fixture;
        return acc;
      }, {})
    };
    await setDoc(roundRef, newData);
  }

  await setDoc(doc(db, "chat", 'asdasdasd'), {});

  return true;
};
