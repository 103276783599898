import 'semantic-ui-less/semantic.less';

import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';

import {
  Toaster
} from 'react-hot-toast';

import { UserProvider } from 'Utils/UserContext';
import { StoreProvider } from 'Utils/StoreContext';

import {
  HomePage
} from 'Pages';

import {
  SiteHeader,
  SiteFooter
} from 'Components';

const App = () => {
  return (
    <BrowserRouter>
      <UserProvider>
        <StoreProvider>
          <Toaster />
          <SiteHeader />
          <Routes>
            <Route path='*' element={<HomePage />} />
          </Routes>
          <SiteFooter />
        </StoreProvider>
      </UserProvider>
    </BrowserRouter>
  );
}

export default App;
