import { useEffect, useState } from 'react';
import { hyphenToHumanFriendly } from 'Utils/formatText';

import { TextEditor } from 'Components';
import NewsEditor from './newsEditor';

import {
  Header,
  Message,
  Table,
  Button,
  Icon,
  Modal,
  Confirm,
  Loader,
  Dimmer,
  Label
} from 'semantic-ui-react';

import {
  ListNews,
  CreateNews,
  toggleArticleStatus
} from '../../api/news';

const News = () => {
  const [news, setNews] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const getEditorResponse = (data) => {
    console.log(data);
  };

  useEffect(() => {
    ListNews().then(response => {
      console.log(response);
      setNews(response);
    }).catch(error => {
      console.log(error);
    });
  }
  ,[]);

  const createArticle = (data) => {
    setNews(prevNews => [...prevNews, data]);
    CreateNews(data).then(response => {
      console.log(response);
    }).catch(error => {
      console.log(error);
    });
  };

  const handleToggleStatus = async (id, currentStatus) => {
    const newStatus = !currentStatus;
    await toggleArticleStatus(id, newStatus);
    setNews(prevNews => prevNews.map(article => 
      article.id === id ? { ...article, active: newStatus } : article
    ));
  };

  if (!news.length) {
    return (
      <Dimmer active inverted>
        <Loader size='mini'>Loading</Loader>
      </Dimmer>
    );
  }

  return (
    <>
      <Header as='h2' attached='top'>News Articles</Header>
      <Message attached="bottom" info>
        <Modal
          trigger={
            <Button color="green" size="small" icon labelPosition='left'>
              <Icon name='add' />
              Create Article
            </Button>
          }
          content={
            <NewsEditor handleNewsCreation={createArticle} />
          }
        />
      </Message>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Title</Table.HeaderCell>
            <Table.HeaderCell>Created</Table.HeaderCell>
            <Table.HeaderCell>Tags</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {news.map((article) => (
            <Table.Row key={article.id} positive={article.active} negative={!article.active}>
              <Table.Cell>
                {article.title}
              </Table.Cell>
              <Table.Cell>
                {article.date}
              </Table.Cell>
              <Table.Cell>
                {article.tags.map((tag) => (
                  <Label key={tag} size="medium" horizontal basic>
                    {hyphenToHumanFriendly(tag)}
                  </Label>
                ))}
              </Table.Cell>
              <Table.Cell collapsing>
                <Modal
                  trigger={
                    <Button color="blue" size="mini">
                      Edit
                    </Button>
                  }
                  content={
                    <TextEditor
                      title={article.title}
                      defaultContent={article.content}
                      handleEditorResponse={getEditorResponse}
                    />
                  }
                />
                <Button
                  icon={article.active ? 'close' : 'checkmark'}
                  content={article.active ? 'Unpublish' : 'Publish'}
                  size="mini"
                  color={article.active ? 'red' : 'green'}
                  onClick={() => handleToggleStatus(article.id, article.active)}
                />

                <Button color="red" size="mini" onClick={() => setConfirmDelete(!confirmDelete)}>
                  Delete
                </Button>
                <Confirm
                  open={confirmDelete}
                  onCancel={() => setConfirmDelete(false)}
                  onConfirm={() => console.log('delete')}
                  confirmButton={'Delete'}
                  size={'mini'}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};

export default News;
