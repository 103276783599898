import { useEffect, useState } from 'react';

import {
  TabPane,
  Tab,
  Header
} from 'semantic-ui-react';

import { getClubs } from '../../api/clubs';
import CreateFixture from './createFixture';
import ListFixtures from './listFixtures';

const Fixtures = () => {
  const [clubs, setClubs] = useState([]);

  const fetchClubs = async () => {
    try {
      const response = await getClubs();
      setClubs(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchClubs();
  }, []);

  const panes = [
    {
      menuItem: 'Create Fixtures',
      render: () => <TabPane attached={false}>
        <CreateFixture clubList={clubs} />
      </TabPane>,
    },
    {
      menuItem: 'Manage Fixtures',
      render: () => <TabPane attached={false}>
        <ListFixtures />
      </TabPane>,
    }
  ];

  return (
    <>
      <Header as='h2' attached='top'>Fixtures</Header>
      <Tab menu={{ pointing: true }} panes={panes} />
    </>
  );
};

export default Fixtures;
