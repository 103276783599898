import {
  Header,
  Container,
  Accordion,
} from 'semantic-ui-react';

import {
  Text
} from 'Common/Components';

import { faqContent } from './Data/faqs';

const AboutPage = () => {
  return (
    <Container>
      <Header as='h2'>About TeamRL</Header>
      <Text as="p">
        Welcome to TeamRL, your go-to online destination for all things
        related to Rugby League!
      </Text>

      <Text as="p">
        We are a team of passionate and dedicated individuals who are committed
        to bringing you the latest news, live chat, and engaging discussions
        about the world of Rugby League. Our mission is to provide a platform
        where fans from around the world can come together and share their
        thoughts, opinions, and insights about the sport they love.
      </Text>

      <Text as="p">
        But we're not just a news site - we also offer a dynamic and engaging
        community for fans to connect and chat in real-time. Our live chat
        feature allows you to join in on the conversation as matches happen,
        share your thoughts, and get instant reactions from other
        passionate fans.
      </Text>

      <Text as="p">
        At TeamRL, we pride ourselves on being a reliable and trustworthy source
        of information for all things Rugby League. Our team of writers and
        contributors are experienced and knowledgeable about the sport, and we
        work tirelessly to ensure that our content is always up-to-date
        and informative.
      </Text>

      <Text as="p">
        So whether you're looking for the latest news and analysis, want to
        join in on the live chat during games, or simply want to connect with
        other Rugby League fans from around the world, TeamRL is the place for
        you. Join us today and become a part of our thriving community of
        Rugby League enthusiasts!
      </Text>

      <Header as='h3'>Frequently Asked Questions</Header>
      <Accordion
        styled
        fluid
        panels={faqContent}
      />
    </Container>
  );
}

export default AboutPage;
