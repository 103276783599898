import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { getNewsItem } from 'API/getNewsItem';
import { hyphenToHumanFriendly } from 'Utils/formatText';

import {
  Container,
  Segment,
  Header,
  Label
} from 'semantic-ui-react';

const NewsPage = () => {
  const [article, setArticle] = useState({});
  let { slug } = useParams();

  useEffect(() => {
    getNewsItem(slug).then(response => {
      setArticle(response);
    }).catch(error => {
      console.log(error);
    });
  }, [slug]);

  return (
    <Container>
      <Header as='h1'>{article.title}</Header>
      <ReactMarkdown>
        {article.content}
      </ReactMarkdown>
      <Segment>
        {article.tags && article.tags.map((tag) => (
          <Label key={tag} horizontal basic>
            {hyphenToHumanFriendly(tag)}
          </Label>
        ))}
      </Segment>
    </Container>
  );
}

export default NewsPage;
