import { Link } from 'react-router-dom';

import style from './footer.module.scss';

const SiteFooter = () => {
  return (
    <div className={`${style['footer']}`}>
      <div>
        <ul className={style['links']}>
          <li className={style['link-item']}>
            <Link to="/about">About TeamRL</Link>
          </li>
          <li className={style['link-item']}>
            <Link to="/subscribe">Subscribe</Link>
          </li>
          <li className={style['link-item']}>
            <a target="_blank" rel="noreferrer" href="https://instagram.com/teamrluk">TeamRL on Instagram</a>
          </li>
        </ul>
        &copy; 2022 - 2025 TeamRL.co.uk
      </div>
    </div>
  );
}

export default SiteFooter;
