import {
   Text
} from 'Common/Components';

import {
   List
} from 'semantic-ui-react'

export const faqContent = [
   {
      key: 'panel-1',
      title: 'Contact us',
      content: 'Please email contact@teamrl.co.uk'
   },
   {
      key: 'panel-2',
      title: 'Advertise on TeamRL',
      content: 'For all enquiries please get in touch. We offer a wide variety of options for advertising.'
   },
   {
      key: 'panel-3',
      title: 'How can I contribute to TeamRL?',
      content: {
         content: (
            <>
               <Text as="p">
                  TeamRL isn't an ordinary forum, instead pre-defined weekly
                  discussions are created based on news or other current affairs.
               </Text>
               <Text as="p">
                  The exception to this is if you're a subscriber, then you're
                  able to create new discussions which will be marked with
                  [Sub] before the title.
               </Text>
            </>
         ),
      },
   },
   {
      key: 'panel-4',
      title: 'Report a message',
      content: {
         content: (
            <Text as="p">
               While we aim to remove all content that goes against our AUP
               sometimes they can get through. If you need to report a message
               please get in touch with us: contact@teamrl.co.uk
            </Text>
         ),
      },
   },
   {
      key: 'panel-5',
      title: 'TeamRL Network',
      content: {
         content: (
            <>
               <Text as="p">
                  In addition to running TeamRL as our main 'hub' we also
                  operate additional websites.
               </Text>
               <List as="ul">
                  <List.Item>
                     <List.Icon name='dot circle outline' />
                     <List.Content>
                        <a href="https://www.casfans.co.uk">Casfans</a>
                     </List.Content>
                  </List.Item>
               </List>
               <Text as="p">
                  We're aiming to grow our network with additional websites
                  and communities in the future.
               </Text>
            </>
         ),
      },
   },
]
