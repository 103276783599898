import { useState, useEffect } from 'react';
import MdEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';

import {
  Segment,
  Header,
  Button,
  Divider
} from 'semantic-ui-react';

const TextEditor = ({
  handleEditorResponse,
  title,
  defaultContent,
  hideControls,
  enableOnChange
}) => {
  const [textArea, setTextArea] = useState(defaultContent || '');

  useEffect(() => {
    if (defaultContent) {
      setTextArea(defaultContent);
    }
  }, [defaultContent]);

  const submitData = () => {
    handleEditorResponse(textArea);
    setTextArea('');
  };

  const handleEditorChange = ({ text }) => {
    setTextArea(text);

    if (enableOnChange) {
      handleEditorResponse(text);
    }
  };

  return (
    <Segment color="blue" secondary>
      <Header as="h4">{title}</Header>

      <MdEditor
        style={{ height: '300px' }}
        value={textArea}
        canView={{ menu: true, md: false, html: false, fullScreen: false, hideMenu: false }}
        view={{ menu: true, md: true, html: false }}
        renderHTML={text => null}
        onChange={handleEditorChange}
      />

      {!hideControls && (
        <>
          <Divider />
          <Button
            color="green"
            onClick={submitData}
          >
            Save
          </Button>
        </>
      )
      
      }
    </Segment>
  );
}

export default TextEditor;
