import { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from 'Utils/UserContext';
import { fireBaseSignOut } from 'Utils/Firebase';
import toast from 'react-hot-toast';

import {
  ButtonGroup,
  Button,
  Label,
  LabelDetail,
  List
} from 'semantic-ui-react';

import style from './user.module.scss';

import {
  UserLoginModal
} from 'Components';

const MenuPanel = () => {
  const [showAuth, setShowAuth] = useState(false);
  const { user, logout } = useContext(UserContext);

  const toggleModalWindow = () => {
    setShowAuth(!showAuth);
  };

  const signUserOut = () => {
    fireBaseSignOut();
    logout();
    toast.success(`Signed out`);
  };

  useEffect(() => { }, [user]);

  return !user ? <div className="container" aria-busy="true"></div> : (
    <div>
      <div className={style['content']}>
        {user.auth
          ?
          <div className={style['account']}>
            <List horizontal>
              <List.Item>
                <Label basic color="blue">
                  Signed in as
                  <LabelDetail>{user.profile.displayName}</LabelDetail>
                </Label>
              </List.Item>
            </List>

            <ButtonGroup size="small">
              <Button as={Link} to="/subscribe" color="red" basic>
                Subscribe!
              </Button>
              <Button as={Link} to="/user" basic color="grey">
                Account
              </Button>
              {user.profile.mod &&
                <Button as={Link} to="/admin" basic color="grey">
                  Admin
                </Button>
              }
              <Button onClick={() => signUserOut()} basic color="grey">
                Sign out
              </Button>
            </ButtonGroup>
          </div>
          :
          <div className={style['btn-group']}>
            <span className={style['action-link']} onClick={() => setShowAuth(showAuth => !showAuth)}>
              Login
            </span>
            <Link className={style['action-link']} to="/register">
              Register
            </Link>
          </div>
        }
      </div>
      {showAuth ? <UserLoginModal handleModalView={toggleModalWindow} /> : null}
    </div>
  );
}

export default MenuPanel;
