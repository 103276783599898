import { useState, useEffect } from 'react';

import { useStore } from 'Utils/StoreContext';

import { Button, Modal, Header, Message, Icon } from 'semantic-ui-react';

import { ClubBadgeImage } from 'Components';

import { getClubs } from '../../api/clubs';
import CreateClubLeague from './createClubLeague';
import LeagueTable from './leagueTable';

const ClubManager = () => {
  const { setItem } = useStore();
  const [clubIds, setClubIds] = useState([]);

  const fetchClubs = async () => {
    try {
      const response = await getClubs();
      const ids = response.map(club => club.id);
      setClubIds(ids);

      const formattedData = response.flatMap(clubCategory =>
        Object.entries(clubCategory).filter(([key]) => key !== 'id').map(([key, value]) => ({
          id: clubCategory.id,
          club: value.club,
          image: <ClubBadgeImage team={value.image} />,
          points: value.points,
          operation: ''
        }))
      );

      const groupedData = formattedData.reduce((acc, club) => {
        if (!acc[club.id]) {
          acc[club.id] = [];
        }
        acc[club.id].push(club);
        return acc;
      }, {});

      setItem('leagueData', groupedData);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchClubs();
  }, []);

  return (
    <>
      <Header as='h2' attached='top'>Club Manager</Header>
      <Message attached="bottom" info>
        <Modal
          trigger={
            <Button color="green" size="small" icon labelPosition='left'>
              <Icon name='add' />
              Create Club
            </Button>
          }
          content={<CreateClubLeague leagues={clubIds} />}
        />
      </Message>

      <LeagueTable />
    </>
  );
};

export default ClubManager;
