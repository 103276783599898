import { useContext, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { UserContext } from 'Utils/UserContext';
import { createDiscussion } from 'API/createDiscussion';
import toast from 'react-hot-toast';

import teamList from 'API/teams.json';

import {
  Container,
  Header,
  List,
  Form,
  FormGroup,
  FormInput,
  FormCheckbox,
  FormSelect
} from 'semantic-ui-react';

import {
  Text
} from 'Common/Components';

import {
  TextEditor
} from 'Components';

const CreateDiscussionPage = () => {
  const [title, setTitle] = useState('');
  const [tag, setTag] = useState('none');
  const [teamTag, setTeamTag] = useState(null);
  const [subMode, setSubMode] = useState(true);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const getEditorResponse = (data) => {
    submitDiscussion(data);
  };

  const submitDiscussion = (data) => {
    const replyData = {
      title: title,
      message: data,
      user: user.profile.displayName,
      sub: subMode,
      tag: tag,
      teamTag: teamTag,
      created: Date.now()
    };

    createDiscussion(replyData).then(response => {
      navigate(`/discuss/${response}`);
      toast.success('Added new discussion');
    }).catch(error => {
      console.log(error);
    });
  };

  return (user.profile.mod || user.profile.status === 'active')
  ?
    <Container>
      <Header as="h2">Create a new discussion</Header>
      <Text as="p">
        Please note that all discussions are moderated and may be removed if
        they break the rules.
      </Text>
      <Text as="p">
        Before creating a new discussion please follow the guidelines:
      </Text>

      <List bulleted>
        <List.Item>Discussions should be relevant and not a duplicate</List.Item>
        <List.Item>The title should be clear and accurate - no click bait</List.Item>
        <List.Item>You accept our <Link to="/aup">AUP</Link> and the discussion doesn't break any points</List.Item>
      </List>

      <Form>
        <FormInput
          label="Discussion title"
          placeholder="Enter your discussion title"
          onChange={(e) => setTitle(e.target.value)}
        />

        <FormGroup widths="equal">
          <FormSelect
            label="Discussion tag"
            options={[
              { key: 'none', text: 'None', value: 'none' },
              { key: 'signing', text: 'Signing', value: 'signing' },
              { key: 'teamnews', text: 'Team news', value: 'teamnews' },
              { key: 'player', text: 'Player', value: 'player' },
              { key: 'challengecup', text: 'Challenge cup', value: 'challengecup' }
            ]}
            onChange={(e, data) => setTag(data.value)}
          />

          {tag === 'teamnews' &&
            <FormSelect
              label="Select Team"
              options={teamList['super-league'].map(team => ({
                key: team.slug,
                text: team.name,
                value: team.slug
              }))}
              onChange={(e, data) => setTeamTag(data.value)}
            />
          }
        </FormGroup>

        {user.profile.mod &&
          <FormCheckbox
            label="Show [Sub] in title (MOD ONLY)"
            onChange={(e, data) => setSubMode(data.checked)}
          />
        }
      </Form>
      
      <TextEditor
        title="Discussion content"
        handleEditorResponse={getEditorResponse}
      />
    </Container>
  : (
      <Container>
      Sorry you're not authorised to create new discussions.
      </Container>
  );
}

export default CreateDiscussionPage;
