import React, { useState, useEffect } from 'react';
import {
  Table,
  Button,
  Header,
  Segment,
  Dimmer,
  Loader,
  Modal
} from 'semantic-ui-react';

import { hyphenToHumanFriendly } from 'Utils/formatText';
import { useStore } from 'Utils/StoreContext';

const LeagueTable = () => {
  const { getItem } = useStore();
  const [leagueData, setLeagueData] = useState(null);

  useEffect(() => {
    const data = getItem('leagueData');
    setLeagueData(data);
  }, [getItem]);

  if (!leagueData) {
    return (
      <Dimmer active inverted>
        <Loader />
      </Dimmer>
    );
  }

  return (
    <>
      {Object.entries(leagueData).map(([league, clubs]) => (
        <Segment key={league}>
          <Header as='h3'>{hyphenToHumanFriendly(league)}</Header>

          <Table compact>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Club</Table.HeaderCell>
                <Table.HeaderCell>Badge</Table.HeaderCell>
                <Table.HeaderCell>Points</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {clubs.map(club => (
                <Table.Row key={club.club}>
                  <Table.Cell>{club.club}</Table.Cell>
                  <Table.Cell>{club.image}</Table.Cell>
                  <Table.Cell>{club.points}</Table.Cell>
                  <Table.Cell collapsing>
                    <Modal
                      trigger={
                        <Button basic size="mini">
                          Edit
                        </Button>
                      }
                      content={
                        <>
                          {league}
                        </>
                      }
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Segment>
      ))}
    </>
  );
}

export default LeagueTable;
