import React, { useState, useEffect } from 'react';
import { Image, Icon, Input } from 'semantic-ui-react';
import { storage } from 'Utils/Firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const Avatar = ({ username, editable }) => {
  const [avatarUrl, setAvatarUrl] = useState('');
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const fetchAvatarUrl = async () => {
      try {
        const fileRef = ref(storage, `avatars/${username}.jpg`);
        const url = await getDownloadURL(fileRef);
        setAvatarUrl(url);
      } catch (error) {
        console.error('Error fetching avatar URL:', error);
      }
    };

    fetchAvatarUrl();
  }, [username]);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileRef = ref(storage, `avatars/${username}.jpg`);
      await uploadBytes(fileRef, file);
      const url = await getDownloadURL(fileRef);
      setAvatarUrl(url);
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  return (
    <div>
      <Image src={avatarUrl || 'default-avatar.png'} size='tiny' circular bordered />
      {editable && (
        <div>
          <Icon name='edit' onClick={handleEditClick} />
          {isEditing && (
            <Input type='file' accept='image/*' onChange={handleFileChange} />
          )}
        </div>
      )}
    </div>
  );
};

const getAvatarUrl = async (username) => {
  try {
    const fileRef = ref(storage, `avatars/${username}.jpg`);
    const url = await getDownloadURL(fileRef);
    return url;
  } catch (error) {
    console.error('Error fetching avatar URL:', error);
    return null;
  }
};

export { Avatar, getAvatarUrl };
