import { app } from 'Utils/Firebase';
import { getFirestore, collection, getDocs, query, limit, where } from 'firebase/firestore/lite';

const db = getFirestore(app);

export const ListNews = async () => {
  const listRef = collection(db, "news");
  const q = query(listRef, where("active", "==", true), limit(10));
  const getCollection = await getDocs(q);
  const list = getCollection.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  return list;
};
