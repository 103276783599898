import {
  Container,
  Grid,
  GridColumn,
  GridRow
} from 'semantic-ui-react';

import {
  FixtureList,
  DiscussionsList,
  NewsList,
  NewsBanner
} from 'Components';

const OverviewPage = () => {
  return (
    <Container>
      <NewsBanner type="homepage" />
      <Grid stretched>
        <GridRow>
          <GridColumn mobile={16} tablet={16} computer={8}>
            <FixtureList />
          </GridColumn>
          <GridColumn mobile={16} tablet={16} computer={8}>
            <DiscussionsList limit={6} title="Community" showAll={true} />
            <NewsList />
          </GridColumn>
        </GridRow>
      </Grid>
    </Container>
  );
}

export default OverviewPage;
