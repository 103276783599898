import { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { DateFormat } from 'Utils/DateFormat';
import { CheckFixtureIsLive, checkPastFixture } from 'Utils/checkFixtureDates';
import { ClubBadgeImage } from 'Components';
import { getAllMatches } from 'API/getMatches';
import { getLiveScore } from 'API/getLiveScore';
import TeamList from 'API/teams.json';

import style from './fixtures.module.scss';

import {
  SegmentGroup,
  Segment,
  Header
} from 'semantic-ui-react';

const GetFullTimeScore = (props) => {
  const [score, setScore] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const payload = {
      url: props.provider
    };

    getLiveScore(payload).then(response => {
      setLoading(false);
      setScore(response);
    }).catch(error => {
      setLoading(false);
      console.log(error);
    });
  }, [props.provider]);
  
  return isLoading || !props.provider ? <span aria-busy="true"></span> : (
    <div>
      {checkPastFixture(props.date) &&
        <div className={style['match-score']}>{score[0].teamScore} - {score[1].teamScore}</div>
      }
    </div>
  );
}

const GetTeamsFromSlug = (props) => {
  const { match } = props;
  const teams = match.slug.split('-');
  const teamList = [];

  teams.forEach((item) => {
    let teamObj = TeamList['super-league'].find(el => el.slug === item);
    teamList.push(teamObj);
  });

  return (
    <Fragment>
      <div className={style['fixture']}>
        <div className={`${style['list-item-team']} ${style['home']}`}>
          <ClubBadgeImage team={teamList[0].slug} /> {teamList[0].name}
        </div>
        <div className={style['date']}>
          {checkPastFixture(match.date) &&
            <GetFullTimeScore date={match.date} provider={match.scoreProvider} />
          }
          {CheckFixtureIsLive(match.date)
            ? <p className={style['fixture-live']}>Live!</p>
            : <p>{DateFormat(match.date)}</p>
          }
        </div>
        <div className={`${style['list-item-team']} ${style['away']}`}>
          {teamList[1].name} <ClubBadgeImage team={teamList[1].slug} />
        </div>
      </div>
    </Fragment>
  );
}

const FixtureList = () => {
  const [matches, setMatches] = useState([]);
  const [competition] = useState('super-league');

  const checkDate = (date) => {
    const now = new Date();
    now.setDate(now.getDate());
    const nowFormat = DateFormat(now);
    const matchDate = DateFormat(date);
    if (matchDate < nowFormat) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    getAllMatches().then(response => {
      setMatches(response);
    }).catch(error => {
      console.log(error);
    });
  }, [competition]);

  return (
    <>
      <SegmentGroup>
        <Segment color="red">
          <Header as='h3' style={{ marginBottom: '0' }}>
            Fixtures &amp; Live Chat
          </Header>
        </Segment>
        <Segment secondary>
          <div className={style['list']}>
            {matches.map((match, index) => (
              <Link key={index} className={`${style['list-item']} ${checkDate(match.date) ? style['list-item-past'] : ''}`} to={`${match.fixtureType}/${match.slug}`}>
                <div className={style['list-content']}>
                  <GetTeamsFromSlug
                    match={match}
                    comp={competition}
                  />
                </div>
              </Link>
            ))}
          </div>
        </Segment>
      </SegmentGroup>
    </>
  );
}

export default FixtureList;
