import { useState } from 'react';
import { Button, Grid, Segment, Form, Header } from 'semantic-ui-react';
import { hyphenToHumanFriendly } from 'Utils/formatText';
import { createFixtureRound } from 'Pages/Admin/api/fixtures';

const CreateFixture = ({ clubList }) => {
  const [fixtures, setFixtures] = useState([]);
  const [selectedLeague, setSelectedLeague] = useState('');
  const [roundNumber, setRoundNumber] = useState('');

  const leagues = clubList.map(league => ({
    key: league.id,
    value: league.id,
    text: hyphenToHumanFriendly(league.id),
  }));

  const leagueTeams = clubList.find(league => league.id === selectedLeague) || {};
  const teamOptions = Object.keys(leagueTeams).filter(key => key !== 'id').map(teamKey => ({
    key: teamKey,
    value: teamKey,
    text: leagueTeams[teamKey].club,
  }));

  const handleFixtureChange = (index, field, value) => {
    setFixtures(prevFixtures => {
      const updatedFixtures = [...prevFixtures];
      updatedFixtures[index][field] = value;
      return updatedFixtures;
    });
  };

  const addFixture = () => {
    setFixtures(prevFixtures => [
      ...prevFixtures,
      {
        homeTeam: '',
        awayTeam: '',
        date: '',
        scoreProvider: '',
      },
    ]);
  };

  const removeFixture = index => {
    setFixtures(prevFixtures => prevFixtures.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    if (!selectedLeague || !roundNumber || fixtures.some(f => !f.homeTeam || !f.awayTeam || !f.date)) {
      alert('Please fill all required fields.');
      return;
    }

    const payload = { selectedLeague, roundNumber, fixtures };
    console.log(payload);

    try {
      await createFixtureRound(payload);
      setSelectedLeague('');
      setRoundNumber('');
      setFixtures([]);
    } catch (error) {
      console.error("Error creating fixture:", error);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Segment.Group>
        <Segment>
          <Header as="h3">Create Fixture Round</Header>
          <Form.Group widths="equal">
            <Form.Field>
              <Form.Select
                label="Select League"
                placeholder="Select..."
                options={leagues}
                value={selectedLeague}
                onChange={(e, { value }) => setSelectedLeague(value)}
              />
            </Form.Field>
            <Form.Field>
              <Form.Input
                label="Round Number"
                type="number"
                min="1"
                max="30"
                placeholder="Round Number"
                value={roundNumber}
                onChange={e => setRoundNumber(e.target.value)}
              />
            </Form.Field>
          </Form.Group>
        </Segment>

        <Segment secondary>
          {fixtures.map((fixture, index) => (
            <FixtureRow
              key={index}
              index={index}
              fixture={fixture}
              teamOptions={teamOptions}
              onChange={handleFixtureChange}
              onRemove={removeFixture}
            />
          ))}
        </Segment>

        <Segment>
          <Button basic type="button" color="blue" onClick={addFixture} size="small">
            Add Fixture
          </Button>
        </Segment>

        <Segment>
          <Button basic type="submit" color="green" size="small">
            Create fixture round
          </Button>
        </Segment>
      </Segment.Group>
    </Form>
  );
};

const FixtureRow = ({ index, fixture, teamOptions, onChange, onRemove }) => (
  <>
    <Header as="h4">Fixture {index + 1}</Header>
    <Form.Group widths="equal">
      <Form.Select
        label="Home Team"
        placeholder="Home Team"
        options={teamOptions}
        value={fixture.homeTeam}
        onChange={(e, { value }) => onChange(index, 'homeTeam', value)}
      />
      
      <Form.Select
        label="Away Team"
        placeholder="Away Team"
        options={teamOptions}
        value={fixture.awayTeam}
        onChange={(e, { value }) => onChange(index, 'awayTeam', value)}
      />
    </Form.Group>

    <Form.Input
      label="Date"
      type="datetime-local"
      value={fixture.date}
      onChange={e => onChange(index, 'date', e.target.value)}
    />

    <Form.Input
      label="Score Provider"
      value={fixture.scoreProvider}
      onChange={e => onChange(index, 'scoreProvider', e.target.value)}
    />


    <Grid.Row>
      <Grid.Column width={16}>
        <Button type="button" color="red" onClick={() => onRemove(index)} size="small">
          Remove Fixture
        </Button>
      </Grid.Column>
    </Grid.Row>
  </>
);

export default CreateFixture;
