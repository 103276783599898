import { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { RelativeDateFormat } from 'Utils/DateFormat';
import { discussionTypes } from 'Utils/DiscussionTypes';
import { hyphenToHumanFriendly } from 'Utils/formatText';
import { listDiscussions } from 'API/getDiscussions';

import {
  SegmentGroup,
  Segment,
  Header,
  Message,
  Button,
  Select,
  FormField,
  LabelGroup,
  LabelDetail,
  Label,
  Item,
  Icon
} from 'semantic-ui-react';

import {
  Text
} from 'Common/Components';

const DiscussionsList = ({ limit, title, filters, showAll }) => {
  const [discussList, setDiscussList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const filterDiscussions = useCallback((option) => {
    const filtered = discussList.filter(a => a.tag === option);
    return option ? filtered : discussList;
  }, [discussList]);

  useEffect(() => {
    listDiscussions(limit).then(response => {
      setDiscussList(response);
      setIsLoading(false);
    }).catch(error => {
      setIsLoading(false);
      console.error('Error loading discussions:', error);
      toast.error(`Discussions failed to load: ${error.message}`);
    });
  }, [limit]);

  return isLoading ? <div aria-busy="true"></div> : (
    <SegmentGroup>
      <Segment clearing color="blue">
        <Header as='h3' floated="left" style={{ marginBottom: '0' }}>
          {title}
        </Header>
        <Button
          size="tiny"
          color="green"
          as={Link}
          to='/create'
          floated='right'
          primary>
          Create Discussion
        </Button>
      </Segment>

      {filters &&
        <Segment>
          <FormField>
            <label>Filter results</label>
            <Select
              placeholder='Select a filter'
              options={discussionTypes.map((item) => ({
                key: item,
                value: item,
                text: hyphenToHumanFriendly(item)
              }))}
              onChange={(e, { value }) => filterDiscussions(value)}
            />
          </FormField>
        </Segment>
      }

      <Segment>
        {discussList.length === 0 &&
          <Message info>
            No discussions, why not start one?
          </Message>
        }

        <Item.Group divided>
          {filterDiscussions().map((item) => (
            <Item key={item.id}>
              <Item.Content>
                <Item.Header as="h4">
                  <Link to={`/discuss/${item.id}`}>
                    {item.title}
                  </Link>
                </Item.Header>
                <Item.Meta>
                  by <Text as="b">{item.user}</Text> on {RelativeDateFormat(item.created)}
                </Item.Meta>
                <Item.Extra>
                  <LabelGroup size="small">
                    {item.sub &&
                      <Label color="green">
                        Subscriber
                      </Label>
                    }
                    {(item.tag && item.tag !== 'none') &&
                      <Label color="grey">
                        {item.tag}
                        {item.teamTag &&
                          <LabelDetail>
                            {item.teamTag}
                          </LabelDetail>
                        }
                      </Label>
                    }
                  </LabelGroup>
                  <Label basic>
                    <Icon name="comment" />
                    {item.replies ? item.replies.length : '0'}{' '}
                    {item.replies && item.replies.length === 1 ? 'Comment' : 'Comments'}
                  </Label>
                </Item.Extra>
              </Item.Content>
            </Item>
          ))}
        </Item.Group>
      </Segment>

      {showAll &&
        <Segment>
          <Button basic color="blue" as={Link} to='/discussions' size="small">
            View all discussions
          </Button>
        </Segment>
      }
    </SegmentGroup>
  );
}

DiscussionsList.propTypes = {
  limit: PropTypes.number,
  title: PropTypes.string,
  filters: PropTypes.bool,
  showAll: PropTypes.bool
};

export default DiscussionsList;
