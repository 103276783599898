import React, { useState } from 'react';
import {
  Header,
  Form,
  Input,
  Button,
  Segment,
  Dropdown,
  Message
} from 'semantic-ui-react';

import toast from 'react-hot-toast';
import { createClub } from '../../api/clubs';
import { hyphenToHumanFriendly } from 'Utils/formatText';
import { useStore } from 'Utils/StoreContext';

const CreateClubLeague = ({ leagues }) => {
  const { setItem } = useStore();
  const [selectedValue, setSelectedValue] = useState('');
  const [leagueInput, setLeagueInput] = useState('');
  const [formData, setFormData] = useState({
    league: '',
    club: '',
    image: ''
  });

  const handleSelectChange = (e, { value }) => {
    setSelectedValue(value);
    if (value !== 'add-league') {
      setFormData({ ...formData, league: value });
    } else {
      setFormData({ ...formData, league: '' });
    }
  };

  const handleInputChange = (e) => {
    setLeagueInput(e.target.value);
    setFormData({ ...formData, league: e.target.value });
  };

  const handleChange = (e, { name, value }) => {
    setFormData({ ...formData, [name]: value });
  };

  const createNewClub = async () => {
    try {
      await createClub(formData);
      toast.success('Club created successfully');
      setItem('leagueData', (prevData) => ({
        ...prevData,
        [formData.league]: [
          ...(prevData[formData.league] || []),
          { club: formData.club, image: formData.image, points: '0' }
        ]
      }));
      setFormData({
        league: '',
        club: '',
        image: ''
      });
    } catch (error) {
      toast.error(`Failed to create club: ${error.message}`);
    }
  };

  const options = [
    ...leagues.map(league => ({
      key: league,
      text: hyphenToHumanFriendly(league),
      value: league
    })),
    {
      key: 'add-league',
      text: 'Add League',
      value: 'add-league'
    }
  ];

  return (
    <Segment>
      <Header as='h3' dividing block>Create Club</Header>
      <Form onSubmit={createNewClub}>
        <Form.Group widths='equal'>
          <Form.Field>
            <label>League</label>
            <Dropdown
              fluid
              selection
              name="league"
              onChange={handleSelectChange}
              value={selectedValue}
              placeholder="Select League..."
              options={options}
            />
          </Form.Field>
          {selectedValue === 'add-league' && (
            <Form.Field>
              <label>League Name</label>
              <Input
                type="text"
                value={leagueInput}
                onChange={handleInputChange}
                placeholder="Enter league name"
              />
              <Message size="mini" color="olive">
                Create a new league which will be assigned to this team.
              </Message>
            </Form.Field>
          )}
        </Form.Group>
        <Form.Group widths='equal'>
          <Form.Field>
            <label>Club Name</label>
            <Input
              name="club"
              onChange={handleChange}
              value={formData.club}
            />
          </Form.Field>
          <Form.Field>
            <label>Club Badge</label>
            <Input
              name="image"
              onChange={handleChange}
              value={formData.image}
            />
          </Form.Field>
        </Form.Group>
        <Button type='submit' basic color="green">Create new club</Button>
      </Form>
    </Segment>
  );
};

export default CreateClubLeague;
