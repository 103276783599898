import { useEffect, useState, useContext } from 'react';
import { UserContext } from 'Utils/UserContext';
import { DateFormat } from 'Utils/DateFormat';
import toast from 'react-hot-toast';
import ReactMarkdown from 'react-markdown';
import { deleteSingleDiscussion } from 'API/handleDiscussionReplies';

import {
  getAvatarUrl
} from 'Common/Components';

import {
  Header,
  CommentText,
  CommentGroup,
  CommentContent,
  CommentMetadata,
  CommentAvatar,
  CommentActions,
  CommentAction,
  CommentAuthor,
  Comment,
  Icon,
  Label,
  Segment,
  Pagination,
  Divider
} from 'semantic-ui-react';

const ThreadReplies = (props) => {
  const { user } = useContext(UserContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(5);
  const [avatarUrls, setAvatarUrls] = useState({});
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = props.replies.slice(indexOfFirstPost, indexOfFirstPost + postsPerPage);

  const paginate = pageNumber => setCurrentPage(pageNumber);

  const deleteItem = (post) => {
    deleteSingleDiscussion(props.threadID, post).then(response => {
      toast.success('Post deleted');
    }).catch(error => {
      console.log(error);
    });
  };

  useEffect(() => {
    const fetchAvatars = async () => {
      const urls = await Promise.all(currentPosts.map(async (post) => {
        const url = await getAvatarUrl(post.user);
        return { [post.id]: url };
      }));
      setAvatarUrls(Object.assign({}, ...urls));
    };

    fetchAvatars();
  }, [currentPosts]);

  return (
    <>
      <CommentGroup>
        <Header as='h4' dividing>
          Replies
        </Header>
        {currentPosts.map((post, index) => (
          <Comment key={index}>
            <CommentAvatar src={avatarUrls[post.id] || ''} />
            <CommentContent>
              <CommentAuthor as="span">
                {post.sub &&
                  <Label color='green' ribbon size="tiny">
                    Sub
                  </Label>
                }
                {post.user}
              </CommentAuthor>
              <CommentMetadata>
                <div>{DateFormat(post.date)}</div>
              </CommentMetadata>
              <CommentText>
                <ReactMarkdown>
                  {post.message}
                </ReactMarkdown>
              </CommentText>
              {user.profile.mod &&
                <CommentActions>
                  <CommentAction onClick={() => deleteItem(post)}>
                    <Icon name='delete' />
                    Delete
                  </CommentAction>
                </CommentActions>
              }
            </CommentContent>
            <Divider />
          </Comment>
        ))}
      </CommentGroup>

      <Segment textAlign='center'>
        <Pagination
          size="mini"
          boundaryRange={0}
          defaultActivePage={1}
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
          siblingRange={1}
          totalPages={Math.ceil(props.replies.length / postsPerPage)}
          onPageChange={(e, { activePage }) => paginate(activePage)}
        />
      </Segment>
    </>
  );
}

export default ThreadReplies;
