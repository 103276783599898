import { useState, useEffect } from 'react';
import { getBanner } from 'API/getNewsBanner';

import {
  Message,
  MessageHeader
} from 'semantic-ui-react';

const NewsBanner = (props) => {
  const [banner, setBanner] = useState([]);
  const { type } = props;

  useEffect(() => {
    getBanner(type).then(response => {
      setBanner(response);
    }).catch(error => {
      console.log(error);
    });
  }, [type]);

  return (
    <Message info size="tiny">
      <MessageHeader>
        {banner.title}
      </MessageHeader>
      {banner.message}
    </Message>
  );
}

export default NewsBanner;
