import { useContext } from 'react';

import {
  GridRow,
  GridColumn,
  Grid,
  Container,
  Menu,
  MenuItem,
  Header
} from 'semantic-ui-react';

import { UserContext } from 'Utils/UserContext';

import AdminIndex from './Pages';
import ClubManager from './Pages/ClubManager';
import Fixtures from './Pages/Fixtures';
import News from './Pages/News';

import {
  Routes,
  Route,
  Link,
  Outlet
} from 'react-router-dom';

const AdminPage = () => {
  const { user } = useContext(UserContext);

  if (user.profile.mod) {
    return (
      <Container>
        <Grid divided>
          <GridRow>
            <GridColumn width={3}>
              <Menu text vertical>
                <MenuItem header>Navigation</MenuItem>
                <MenuItem>
                  <Link to="clubmanager">Club Manager</Link>
                </MenuItem>
                <MenuItem>
                  <Link to="fixtures">Fixtures</Link>
                </MenuItem>
                <MenuItem>
                  <Link to="news">News Articles</Link>
                </MenuItem>
              </Menu>
            </GridColumn>
            <GridColumn width={13}>
              <Routes>
                <Route path="/" element={<AdminIndex />} />
                <Route path="clubmanager" element={<ClubManager />} />
                <Route path="fixtures" element={<Fixtures />} />
                <Route path="news" element={<News />} />
              </Routes>
              <Outlet />
            </GridColumn>
          </GridRow>
        </Grid>
      </Container>
    );
  } else {
    return (
      <Container>
        <Header as='h1'>You are not authorized to access this page.</Header>
      </Container>
    );
  }
}

export default AdminPage;
