import {
  Container,
  Header,
  List,
  Segment
} from 'semantic-ui-react';

const AupPage = () => {
  return (
    <Container>
      <Header as="h1">Acceptable Usage Policy</Header>
      <Segment basic>
        <Header as="h2">1. Introduction</Header>
        <p>
          Welcome to TeamRL! Our platform enables users to create discussions, participate in replies,
          and engage in live match day chats. To maintain a positive and inclusive environment,
          all users must adhere to the following Acceptable Use Policy (AUP).
        </p>
      </Segment>

      <Segment basic>
        <Header as="h2">2. Prohibited Activities</Header>
        <List bulleted>
          <List.Item>
            <strong>Harassment:</strong> Any form of bullying, threats, or harassment of other users.
          </List.Item>
          <List.Item>
            <strong>Hate Speech:</strong> Content promoting racism, sexism, or any discriminatory behavior.
          </List.Item>
          <List.Item>
            <strong>Spam:</strong> Repeated or irrelevant messages, advertisements, or self-promotion.
          </List.Item>
          <List.Item>
            <strong>Illegal Activities:</strong> Sharing, promoting, or facilitating illegal content or actions.
          </List.Item>
          <List.Item>
            <strong>Disruption:</strong> Attempts to disrupt live chats or discussions through trolling or excessive negativity.
          </List.Item>
        </List>
      </Segment>

      <Segment basic>
        <Header as="h2">3. User Responsibilities</Header>
        <List ordered>
          <List.Item>
            Respect other members of the community and their opinions.
          </List.Item>
          <List.Item>
            Use appropriate language and tone when engaging in discussions and live chats.
          </List.Item>
          <List.Item>
            Report any content or users violating this AUP to our moderation team.
          </List.Item>
          <List.Item>
            Ensure your content complies with applicable laws and regulations.
          </List.Item>
        </List>
      </Segment>

      <Segment basic>
        <Header as="h2">4. Enforcement</Header>
        <p>
          TeamRL reserves the right to take appropriate action for violations of this AUP, which may include:
        </p>
        <List bulleted>
          <List.Item>Warning the user about their behavior.</List.Item>
          <List.Item>Temporary or permanent suspension of account privileges.</List.Item>
          <List.Item>Removing content that violates this policy.</List.Item>
          <List.Item>In extreme cases, reporting illegal activities to relevant authorities.</List.Item>
        </List>
      </Segment>

      <Segment basic>
        <Header as="h2">5. Amendments</Header>
        <p>
          TeamRL reserves the right to modify this AUP at any time. Users will be notified of significant changes,
          and continued use of the platform indicates acceptance of the updated policy.
        </p>
      </Segment>

      <Segment basic textAlign="center">
        <p>
          Thank you for being a valued part of the TeamRL community. Let's make this platform enjoyable for everyone!
        </p>
      </Segment>
    </Container>
  );
}

export default AupPage;
