import { useEffect, useState, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { Helmet } from "react-helmet";
import { app } from 'Utils/Firebase';
import { getFirestore, doc, onSnapshot } from "firebase/firestore";
import toast from 'react-hot-toast';
import { UserContext } from 'Utils/UserContext';
import { DateFormat } from 'Utils/DateFormat';
import { replySingleDiscussion } from 'API/handleDiscussionReplies';
import { TextEditor, AdPanel } from 'Components';
import ThreadReplies from './replies';

import {
  getAvatarUrl
} from 'Common/Components';

import {
  Container,
  Label,
  Segment,
  Comment,
  CommentAvatar,
  CommentContent,
  CommentAuthor,
  CommentMetadata,
  CommentText,
  Header,
  Message,
  CommentGroup,
  ButtonGroup,
  Button
} from 'semantic-ui-react';

const DiscussThreadPage = () => {
  let { id } = useParams();
  const { user } = useContext(UserContext);
  const [thread, setThread] = useState({});
  const [avatarUrl, setAvatarUrl] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const db = getFirestore(app);

  useEffect(() => {
    const fetchThreadData = async () => {
      const unsubscribe = onSnapshot(doc(db, 'discussions', id), async (doc) => {
        if (doc.exists()) {
          const threadData = doc.data();
          setThread(threadData);
          const url = await getAvatarUrl(threadData.user);
          setAvatarUrl(url);
          setIsLoading(false);
        }
      });

      return () => unsubscribe();
    };

    fetchThreadData();
  }, [id, db]);

  const getEditorResponse = (data) => {
    submitReply(data);
  };

  const checkSubStatus = () => user.profile.status === 'active';

  const submitReply = (data) => {
    const replyData = {
      message: data,
      user: user.profile.displayName,
      sub: checkSubStatus(),
      date: Date.now()
    };

    replySingleDiscussion(id, replyData).then(response => {
      toast.success(response);
    }).catch(error => {
      console.log(error);
    });
  };

  return isLoading ? <div aria-busy="true"></div> : (
    <Container>
      <Helmet>
        <title>{`${thread.title} | TeamRL • Social Rugby League`}</title>
      </Helmet>

      <Header as='h2' attached='top'>
        {thread.title}
      </Header>

      {user.profile.mod &&
        <Message attached="bottom" info>
          <ButtonGroup size='mini'>
            <Button basic color="red">Delete Discussion</Button>
          </ButtonGroup>
        </Message>
      }

      <Segment>
        <CommentGroup>
          <Comment>
            <CommentAvatar as='a' src={avatarUrl} />
            <CommentContent>
              <CommentAuthor as="span">
                {thread.sub &&
                  <Label color='green' ribbon size="mini">
                    Sub
                  </Label>
                }
                {thread.user}
              </CommentAuthor>
              <CommentMetadata>
                <div>{DateFormat(thread.created)}</div>
              </CommentMetadata>
              <CommentText>
                <ReactMarkdown>
                  {thread.message}
                </ReactMarkdown>
              </CommentText>
            </CommentContent>
          </Comment>
        </CommentGroup>
      </Segment>

      <AdPanel />

      {thread.replies &&
        <ThreadReplies
          replies={thread.replies}
          threadID={id}
        />
      }

      {user.auth
        ?
        <TextEditor
          title="Reply"
          handleEditorResponse={getEditorResponse}
        />
        :
        <div>
          You need to be logged in or <Link to="/register">
            register
          </Link> to reply.
        </div>
      }
    </Container>
  );
}

export default DiscussThreadPage;
