import { useState } from 'react';
import {
  Segment,
  Header,
  Form
} from 'semantic-ui-react';

import { TextEditor } from 'Components';

const tagData = [
  { key: '1', text: 'Website', value: 'website' },
  { key: '2', text: 'Rugby League', value: 'rugby-league' }
];

const NewsEditor = ({ handleNewsCreation }) => {
  const [formData, setFormData] = useState({
    title: '',
    tags: [],
    date: '',
    content: '',
    published: false
  });

  const handleChange = (e, { name, value }) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleEditorResponse = (content) => {
    setFormData({ ...formData, content });
  };

  const handleSubmit = () => {
    handleNewsCreation(formData);
  };

  return (
    <Segment>
      <Header as='h3'>Create Article</Header>
      <Form onSubmit={handleSubmit}>
        <Form.Field>
          <Form.Input
            fluid
            label='Title'
            type='text'
            name='title'
            value={formData.title}
            onChange={handleChange}
          />
        </Form.Field>
        <Header as='h4' dividing>Metadata</Header>
        <Form.Group widths='equal'>
          <Form.Dropdown
            fluid
            multiple
            selection
            label='Tags'
            options={tagData}
            name='tags'
            value={formData.tags}
            onChange={handleChange}
          />
          <Form.Input
            fluid
            label='Date'
            type='date'
            name='date'
            value={formData.date}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Field>
          <TextEditor
            hideControls
            enableOnChange
            handleEditorResponse={handleEditorResponse}
          />
        </Form.Field>
        <Form.Field>
          <Form.Checkbox
            toggle
            label='Published'
            name='published'
            checked={formData.published}
            onChange={(e, { checked }) => setFormData({ ...formData, published: checked })}
          />
        </Form.Field>
        <Form.Button
          color='green'
          content='Create Article'
        />
      </Form>
    </Segment>
  );
}

export default NewsEditor;
