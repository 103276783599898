import {
  Header
} from 'semantic-ui-react';

import {
  Text
} from 'Common/Components';

const AdminIndex = () => {
  return (
    <>
      <Header as='h1'>TeamRL Admin Panel</Header>
      <Text>
        Welcome to the TeamRL admin panel. From here you can manage the website
        using the navigation on the left.
      </Text>
    </>
  );
};

export default AdminIndex;
