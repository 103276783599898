import { useContext, useEffect, useState } from 'react';
import { UserContext } from 'Utils/UserContext';
import { Helmet } from "react-helmet";
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from 'Utils/Firebase';
import { getUserFromDB } from 'API/getUser';

import {
  Routes,
  Route,
  useLocation
} from 'react-router-dom';

import {
  OverviewPage,
  FixturePage,
  RegisterPage,
  NewsPage,
  SubscribePage,
  DiscussionsPage,
  CreateDiscussionPage,
  CreateArticle,
  DiscussThreadPage,
  AboutPage,
  AupPage,
  CreateMatchPage,
  UserPage,
  AdminPage
} from 'Pages';

const HomePage = () => {
  const { login } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const { pathname } = useLocation();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        getUserFromDB(user.uid).then(response => {
          login(user, response);
          setLoading(false);
        }).catch(error => {
          setLoading(false);
          console.log(error);
        });
      } else {
        setLoading(false);
      }
    });

    window.scrollTo(0,0);
  }, [pathname]);

  return loading ? <div className="container" aria-busy="true"></div> : (
    <div>
      <Helmet>
        <title>TeamRL • Social Rugby League</title>
      </Helmet>
      <Routes>
        <Route path='/' index={true} element={<OverviewPage />} />
        <Route path='/admin/*' element={<AdminPage />} />
        <Route path='/:type/:fixture' element={<FixturePage />} />
        <Route path='/news/:slug' element={<NewsPage />} />
        <Route path='/register' element={<RegisterPage />} />
        <Route path='/subscribe' element={<SubscribePage />} />
        <Route path='/discussions' element={<DiscussionsPage />} />
        <Route path='/discuss/:id' element={<DiscussThreadPage />} />
        <Route path='/about' element={<AboutPage />} />
        <Route path='/aup' element={<AupPage />} />
        <Route path='/create' element={<CreateDiscussionPage />} />
        <Route path='/matchcreate' element={<CreateMatchPage />} />
        <Route path='/newarticle' element={<CreateArticle />} />
        <Route path='/user' element={<UserPage />} />
      </Routes>
    </div>
  );
}

export default HomePage;
