import { useParams } from 'react-router-dom';

import {
  Container,
  Grid,
  GridColumn
} from 'semantic-ui-react';

import {
  LiveChat,
  LiveScore
} from 'Components';

const FixturePage = () => {
  let { fixture } = useParams();

  return (
    <Container>
      <Grid columns='equal'>
        <GridColumn>
          <LiveScore team={fixture} />
        </GridColumn>
        <GridColumn>
          <LiveChat chatID={fixture} />
        </GridColumn>
      </Grid>
    </Container>
  );
}

export default FixturePage;
