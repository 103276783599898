import { app } from 'Utils/Firebase';
import { getFirestore, collection, getDocs, query, limit, orderBy, addDoc, deleteDoc, doc, updateDoc } from 'firebase/firestore/lite';

const db = getFirestore(app);

export const ListNews = async () => {
  const listRef = collection(db, "news");
  const q = query(listRef, orderBy("published", "desc"), limit(10));
  const getCollection = await getDocs(q);
  const list = getCollection.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
  return list;
};

export const CreateNews = async (data) => {
  const listRef = collection(db, "news");
  const docRef = await addDoc(listRef, data);
  return docRef.id;
};

export const DeleteNews = async (id) => {
  const listRef = collection(db, "news");
  await deleteDoc(doc(listRef, id));
};

export const toggleArticleStatus = async (id, status) => {
  const listRef = collection(db, "news");
  await updateDoc(doc(listRef, id), { active: status });
};
