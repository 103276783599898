import { app } from 'Utils/Firebase';

import {
  getFirestore,
  collection,
  getDoc,
  getDocs,
  query,
  setDoc,
  doc,
  updateDoc,
  FieldValue
} from 'firebase/firestore/lite';

const db = getFirestore(app);

export const getClubs = async () => {
  const listRef = collection(db, "clubs");
  const q = query(listRef);
  const getCollection = await getDocs(q);
  const list = getCollection.docs.map((doc) => ({id: doc.id, ...doc.data()}))
  return list;
};

export const getLeagues = async () => {
  const listRef = collection(db, "clubs");
  const q = query(listRef);
  const getCollection = await getDocs(q);
  const list = getCollection.docs.map((doc) => doc.id);
  return list;
};

export const createClub = async (data) => {
  const { league, club, image } = data;
  const clubData = {
    club,
    points: "0",
    image
  };

  const leagueDocRef = doc(db, "clubs", league);
  const leagueDoc = await getDoc(leagueDocRef);

  if (!leagueDoc.exists()) {
    await setDoc(leagueDocRef, {});
  }

  const docRef = await setDoc(leagueDocRef, { [club.toLowerCase().replace(/\s+/g, '-')]: clubData }, { merge: true });
  return docRef;
};

export const updateClub = async (league, club, data) => {
  const clubKey = club.toLowerCase().replace(/\s+/g, '-');
  const docRef = doc(db, "clubs", league);
  await updateDoc(docRef, {
    [clubKey]: data
  });
};

export const deleteClub = async (league, club) => {
  const clubKey = club.toLowerCase().replace(/\s+/g, '-');
  const docRef = doc(db, "clubs", league);
  await updateDoc(docRef, {
    [clubKey]: FieldValue.delete()
  });
};
